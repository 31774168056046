<template>
  <div class="mx-lg-auto">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-img
          rounded
          v-bind="attrs"
          v-on="on"
          src="img/design/sellbg.png"
          lazy-src="img/design/sellm.jpeg"
          ><div class="pt-2" v-if="$vuetify.breakpoint.mdAndUp">
            <span v-if="!data.title">
              <p
                v-if="!data.title"
                v-bind="attrs"
                v-on="on"
                class="
                  display-1
                  pa-12
                  ma-6
                  font-weight-thin
                  text-center
                  align-end
                "
              >
                Create a Trustless Item
              </p> </span
            ><span v-else>
              <p
                v-bind="attrs"
                v-on="on"
                class="display-1 pa-12 ma-6 font-weight-thin text-center"
              >
                Sell <span class="headline"> {{ data.title }}</span>
              </p>
            </span>
            <v-icon class="ml-4" small>mdi-information-outline</v-icon>
          </div>
        </v-img>
      </template>

      <v-card class="text-center rounded-lg">
        <v-card-title class="h2 lighten-2"> Info </v-card-title>

        <v-card-text>
          After placing the item, an estimation will be made. After you accept
          this price, anyone can buy the item!
        </v-card-text>
        <iframe
          width="100%"
          height="281"
          src="https://www.youtube.com/embed/zHXwfePrGvA?vq=hd1080&autoplay=0&loop=1&modestbranding=1&rel=0&cc_load_policy=1&color=white&mute=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Let's go </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-stepper class="elevation-0" v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1"> Data </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step v-if="data.token_uri == '' || data.photos == ['']" :complete="e1 > 2" step="2"> Pictures </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" :step="data.token_uri == '' || data.photos == [''] ? 3 : 2"> Done! </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pa-2 ma-2" step="1">
          <div class="pr-4 ma-2">
            <v-alert
              type="warning"
              class="caption text-center"
              v-if="
                !$store.state.user && $store.state.account.address
              "
            >
              To post items, Confirm this sign in by clicking the link sent to
              your Google account's email on this device.
            </v-alert>
            <v-text-field
              class="ma-1"
              prepend-icon="mdi-format-title"
              :rules="rules.titleRules"
              label="Title"
              v-model="data.title"
              required
            />
            <v-alert
              class="ma-2 caption"
              dense
              type="info"
              dismissible
              v-if="descrinfo"
            >
              Make sure to fully disclose any defects or scratches (and
              highlight these in the pictures)</v-alert
            >
            <v-textarea
              @change="descrinfo = !descrinfo"
              class="ma-1"
              prepend-icon="mdi-text"
              :rules="rules.descriptionRules"
              v-model="data.description"
              label="Description"
              auto-grow
            >
            </v-textarea>

            <v-combobox
              prepend-icon="mdi-tag-outline"
              hint="At least one and at most 5 category tags"
              :persistent-hint="selectedTags == 0 || selectedTags == 5"
              :search-input.sync="search"
              v-model="selectedTags"
              :items="taglist"
              :rules="rules.tagRules"
              label="Categories"
              deletable-chips
              multiple
              chips
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="search != null">
                        No category tags matching "<strong>{{ search }}</strong
                        >". Press <kbd>enter</kbd> to create a new one</span
                      ><span v-else
                        >Type a category/brand/model and press
                        <kbd>enter</kbd> to create a new category tag</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="selectedTags[0] == item"
                  v-bind="attrs"
                  :input-value="selected"
                  color="primary lighten-2"
                  small
                >
                  <span class="pr-2">
                    Main: <v-icon x-small>mdi-tag</v-icon> {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    mdi-close
                  </v-icon>
                </v-chip>
                <v-chip
                  v-else
                  v-bind="attrs"
                  color="secondary darken-1"
                  :input-value="selected"
                  small
                >
                  <span class="pr-2">
                    <v-icon x-small>mdi-tag</v-icon> {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>

            <div>
              <v-row class="pa-2 mt-4"
                ><v-btn text icon @click="data.estimation_count = 3">
                  <v-icon> mdi-check</v-icon></v-btn
                >
                <v-slider
                  hint="Lower for faster results, higher for better accuracy"
                  thumb-label
                  :persistent-hint="data.estimation_count != 3"
                  label="Estimation Count"
                  :thumb-size="90"
                  max="12"
                  :rules="rules.estimation_countRules"
                  placeholder="Estimation count"
                  v-model="data.estimation_count"
                  ><template v-slot:thumb-label="item">
                    {{ item.value }} Estimations
                  </template></v-slider
                >
              </v-row>
              <v-row class="pa-2 mt-4">
                <v-text-field
                  class="ma-1"
                  prepend-icon="mdi-format-title"
                  :rules="rules.tokenUriRules"
                  label="Token URI (optional)"
                  v-model="data.token_uri"
                />
              </v-row>
              <v-row class="pa-2 mt-4"  v-for="(photo, nr) in data.photos" :key="nr">
            
                  <v-text-field  class="ma-1"
                    label="Photo URL (optional)"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addRow"
                    v-model="photo.value"
                  />
              
              </v-row>
              <v-row class="pa-2 mt-4">
                <v-radio-group v-model="itemOption" row>
                  <template v-slot:label>
                    <div>Your transfer method</div>
                  </template>
                  <v-radio
                    label="Estimate Only"
                    value="estimation_only"
                  ></v-radio>
                  <v-radio label="Local" value="local"></v-radio>
                  <v-radio label="Shipping" value="shipping"></v-radio>
                  <v-radio
                    label="Local and Shipping"
                    value="local_shipping"
                  ></v-radio>
                </v-radio-group>
              </v-row>

              <v-row class="pa-2" v-if="itemOption != 'estimation_only'">
                <v-btn text icon @click="data.condition = 0">
                  <v-icon>{{
                    data.condition === 0 ? "mdi-star-outline" : "mdi-star"
                  }}</v-icon>
                </v-btn>
                <v-slider
                  label="Condition"
                  :hint="
                    'Condition is ' +
                    conditionLabel() +
                    ', please explain condition in description'
                  "
                  v-model="data.condition"
                  :max="4"
                  :persistent-hint="data.condition != 0"
                  :thumb-size="24"
                  thumb-label
                  ><template v-slot:thumb-label="{ value }">
                    {{ satisfactionEmojis[value] }}
                  </template>
                </v-slider></v-row
              >

              <v-row
                class="pa-2 mt-2"
                v-if="itemOption != 'estimation_only' && itemOption != 'local'"
              >
                <v-btn text icon @click="data.shipping_cost = 0">
                  <v-icon>
                    {{
                      data.shipping_cost === 0
                        ? "mdi-package-variant"
                        : "mdi-package-variant-closed"
                    }}
                  </v-icon>
                </v-btn>

                <v-slider
                  hint="Set to 0 tokens for no shipping"
                  thumb-label
                  label="Shipping cost"
                  suffix="tokens"
                  :persistent-hint="data.shipping_cost != 0"
                  placeholder="Shipping cost"
                  :thumb-size="60"
                  thumb-color="primary lighten-1"
                  v-model="data.shipping_cost"
                  ><template v-slot:thumb-label="item">
                    {{ item.value }}
                    <v-icon>$vuetify.icons.custom</v-icon>
                  </template>
                </v-slider>
              </v-row>
              <v-row
                ><v-col
                  v-if="itemOption != 'estimation_only' && itemOption != 'local'"
                >
                  <v-select
                    class="mt-1 pt-0"
                    prepend-icon="mdi-earth"
                    hint="At least one"
                    :persistent-hint="selectedCountries == 0"
                    v-model="selectedCountries"
                    :items="countryCodes"
                    :rules="rules.shippingRules"
                    label="Location"
                    deletable-chips
                    multiple
                    chips
                  >
                  </v-select>
                </v-col>
                <v-col
                  v-if="
                    itemOption != 'estimation_only' && itemOption != 'shipping'
                  "
                >
                  <v-row class="ma-0">
                    <v-btn
                      class="mr-auto"
                      text
                      icon
                      @click="enterlocation = !enterlocation"
                      ><v-icon
                        >{{
                          enterlocation
                            ? "mdi-map-marker"
                            : "mdi-map-marker-off"
                        }}
                      </v-icon></v-btn
                    >

                    <v-switch
                      class="mr-auto mt-1"
                      v-model="enterlocation"
                      inset
                      label="Location"
                      :persistent-hint="enterlocation == true"
                      hint="Specify location"
                    ></v-switch>
                  </v-row>
                  <v-text-field
                    class="ma-1"
                    prepend-icon="mdi-map-marker"
                    :rules="rules.locRules"
                    label="Location"
                    v-model="data.location"
                    required
                    v-if="enterlocation"
                  /> </v-col
              ></v-row>
            </div>
            <div class="mx-auto text-center" v-if="valid">
              <span class="caption">
                Required deposit for price estimators:
                <v-icon small left>$vuetify.icons.custom</v-icon
                >{{ data.deposit_amount / 1000000 }} TRST.
                <v-btn @click="changedeposit = !changedeposit" icon small>
                  <v-icon> mdi-pencil </v-icon></v-btn
                ></span
              >
              <v-row v-if="changedeposit">
                <v-col cols="6" class="mx-auto">
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><v-icon class="ml-4 align-center" small
                          >mdi-information-outline</v-icon
                        >
                      </span>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card class="rounded-lg">
                        <v-toolbar color="default"
                          >Info <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            icon
                            @click="dialog.value = false"
                          >
                            <v-icon> mdi-close</v-icon>
                          </v-btn></v-toolbar
                        >
                        <v-card-text>
                          <div class="text-p pt-4">
                            A deposit is required for all the estimators. You
                            may change this, but don't set it too high because
                            1) then no one is willing to estimate it 2) when it
                            is higher than the final price, no action is taken.
                            Estimators risk their deposit to make an estimation
                            for you.
                          </div>
                          <div class="caption pa-2">
                            - The lowest estimator loses this when the final
                            estimation price is not accepted by you.
                          </div>
                          <div class="caption pa-2 mb-2">
                            - The highest estimator loses this when the item is
                            not bought by the buyer that provided prepayment.
                          </div>
                        </v-card-text>
                        <v-img
                          class="mx-12"
                          src="img/design/transfer.png"
                        ></v-img>
                        <div class="caption text-center pt-4">
                          Good luck and have fun!
                        </div>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="dialog.value = false"
                            >Close</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <v-col cols="6" class="mx-auto">
                  <v-text-field
                    label="Amount"
                    type="number"
                    v-model="data.deposit_amount"
                    suffix="TRST"
                    prepend-icon="$vuetify.icons.custom"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="text-center pt-6">
              <v-btn
                rounded
                color="primary"
                :depressed="!valid || !!flight || !hasAddress"
                @click="submit()"
                ><div v-if="!flight">
                  Next<v-icon> mdi-arrow-right-bold</v-icon>
                </div>
                <div v-if="flight">
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="ma-1"
                  ></v-progress-linear
                  >Awaiting transaction creating item ID...
                </div>
              </v-btn>
            </div>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div v-if="showpreview">
            <create-item-preview-and-upload
              :thisitem="thisitem"
              v-on:changeStep="updateStepCount($event)"
            />
          </div>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-alert rounded-lg type="success">
            Submitted, the item will be estimated
          </v-alert>
          <p>
            You can always find your item in your
            <router-link to="/account">account</router-link>. Your item will be
            available to buy after you make it transferable.
          </p>
        </v-stepper-content>
      </v-stepper-items> </v-stepper
    ><sign-tx
      v-if="submitted"
      :key="submitted"
      :fields="fields"
      :value="value"
      :msg="msg"
      @clicked="afterSubmit"
    ></sign-tx>
  </div>
</template>


<script>
import CreateItemPreviewAndUpload from "./CreateItemPreviewAndUpload.vue";
import SignTxEncrypted from "./SignTxEncrypted.vue";
import { Random } from "@cosmjs/crypto/";
const miscreant = require("miscreant");
import { sharedKey as x25519, generateKeyPair } from "curve25519-js";
import { toUtf8, fromBase64, toBase64 } from "@iov/encoding";

const hkdf = require("js-crypto-hkdf");
const cryptoProvider = new miscreant.PolyfillCryptoProvider();

export default {
  components: { CreateItemPreviewAndUpload, SignTxEncrypted },
  data() {
    return {
      data: {
        title: "",
        description: "",
        shipping_cost: 0,
        location: "",
        estimation_count: "3",
        condition: "0",
        deposit_amount: "3",
        photos: [""],
        token_uri: "",
      },
      itemOption: "estimation_only",
      rules: {
        titleRules: [
          (v) => !!v || "Title is required",
          (v) => (v && v.length > 4) || "Title must be more than 4 characters",
          (v) =>
            (v && v.length <= 80) || "Title must be less than 80 characters",
        ],
        descriptionRules: [
          (v) => !!v || "Description is required",
          (v) =>
            (v && v.length > 4) || "Description must be more than 4 characters",
          (v) =>
            (v && v.length <= 800) ||
            "Description must be less than 800 characters",
        ],
        estimation_countRules: [
          (v) => !!v || "Estimation count is required",
          (v) =>
            (v && v > 2) || "Estimation count must be more than 2 estimators",
          (v) =>
            (v && v < 12) || "Estimation count must be less than 12 estimators",
        ],
        tagRules: [
          (v) => !!v.length == 1 || "Category tag is required",
          (v) => (v && v.length < 6) || "Category tags must be less than 6",
        ],
        locRules: [
          (v) =>
            v.length <= 48 ||
            "Location must be less than 48 characters, enter coordinates instead",
        ],
        shippingRules: [(v) => !!v.length == 1 || "A country is required"],
        tokenUriRules: [
          // (v) => !!v || "Token is required",
          (v) => (v && v.length > 9) || "Token URI link must be longer",
          (v) =>
            (v && v.substring(0, 7) == "http://") || "Link must start with http://",
        ],
      },
      itemid: "",
      selectedTags: [],
      selectedCountries: [],
      thisitem: {},
      flight: false,
      showpreview: false,
      e1: 1,
      search: null,
      dialog: false,
      changedeposit: false,
      descrinfo: false,
      satisfactionEmojis: ["😭", "🙁", "🙂", "😊", "😄"],
      countryCodes: ["NL", "BE", "UK", "DE", "US", "CA"],
      enterlocation: false,
      photoId: 1,
      fields: [],
      value: {},
      msg: "",
      submitted: false,
    };
  },
  watch: {
    selectedTags(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.selectedTags.pop());
      }
    },
  },

  mounted() {
    //console.log(input)

    this.$store.dispatch("setSortedTagList");
  },

  computed: {
    taglist() {
      if (this.selectedTags == 0) {
        let list = [
          "Watch",
          "Shoes",
          "Clothing",
          "Collectable",

          //  "Garden item",
          "Vehicle",
          // "Motor",
          //"Sport",
          "Book",
          // "Antique",
          "Computer",
          "Smartphone",
          "Smart Device",
          "Sound Device",
          "TV",
          "NFT",
          "Other",
        ];
        return list;
      } else {
        //this.$store.dispatch("setTagList");

        return this.$store.getters.getTagList;
      }
    },

    hasAddress() {
      return !!this.$store.state.account.address || alert("Sign in first");
    },

    valid() {
      if (
        this.data.title.trim().length > 3 &&
        this.data.description.trim().length > 4 &&
        this.selectedTags.length > 0 &&
        (!!this.$store.state.user || this.itemOption == 'estimation_only' && this.data.token_uri || this.itemOption == 'estimation_only' && this.data.photos[0])//&&
      ) {
        return true;
      }
    },
  },

  methods: {
    async submit() {
      if (this.valid && !this.flight && this.hasAddress) {
        this.flight = true;
        if ((this.data.taglist.find(tag => tag == "NFT") != null && this.data.location == '')) {
          this.data.location = ' ';
        }
        this.fields = [
          ["creator", 1, "string", "optional"],
          ["title", 2, "string", "optional"],
          ["description", 3, "string", "optional"],
          ["shipping_cost", 4, "int64", "optional"],
          ["location", 5, "string", "optional"],
          ["estimation_count", 6, "int64", "optional"],
          ["tags", 7, "string", "repeated"],
          ["condition", 8, "int64", "optional"],
          ["shipping_region", 9, "string", "repeated"],
          ["deposit_amount", 10, "int64", "optional"],
          ["init_msg", 11, "bytes", "optional"],
          ["auto_msg", 12, "bytes", "optional"],
          ["photos", 13, "string", "repeated"],
          ["token_uri", 14, "string", "optional"],
        ];

        let initMsgMsg = {
          deposit_required: this.data.deposit_amount.toString(),
          estimation_count: this.data.estimation_count.toString(),
        };
        let autoMsgMsg = {
          auto_msg: "",
        };
        if (this.itemOption == 'estimation_only'){
        this.codeHash = this.$store.getters.getCodeHash(2)
        }else{
           this.codeHash = this.$store.getters.getCodeHash(1)
        }
        //const body = [this.$store.state.account.address,"dsaf", "asdf", 33, 1, "sdfsdf", "asdf", 4, "sfda"]
        const body = {
          //creator: this.$store.state.account.address,
          title: this.data.title,
          description: this.data.description,
          shipping_cost: (this.data.shipping_cost * 1000000).toString(),
          location: encodeURI(this.data.location),
          estimation_count: this.data.estimation_count,
          tags: this.selectedTags,
          condition: this.data.condition,
          shipping_region: this.selectedCountries,
          deposit_amount: this.data.deposit_amount,
          init_msg: initMsgMsg,
          auto_msg: autoMsgMsg,
          photos: this.data.photos,
          token_uri: this.data.token_uri,
        };

        this.msg = "MsgCreateItem";

        this.value = {
          creator: this.$store.state.account.address,
          ...body,
        };
        this.value.init_msg = await this.encryptMsg(
          this.codeHash,
          this.value.init_msg
        );
        this.value.auto_msg = await this.encryptMsg(
          this.codeHash,
          this.value.auto_msg
        );

        this.submitted = true;
      }else if (!this.hasAddress){
        alert("Sign in first")
      } else if (!!this.$store.state.user) {
          alert("Sign with email first to attach to the item")
      }else{
           alert("Some required input is missing")
      }

    },

    async newItem() {
      setTimeout(() => this.afterSubmit(), 1000);
    },
    async afterSubmit() {
      if (this.data.token_uri == '' && this.data.photos == [""]) {

     
      this.loadingitem = true;

      this.msg = "";
      this.fields = [];
      this.value = {};

      const selleritems = this.$store.state.creatorItemList || [];

      console.log(selleritems);

      // const type = { type: "item" };
      //await this.$store.dispatch("entityFetch", type);

      await this.$store.dispatch(
        "setCreatorItemList",
        this.$store.state.account.address
      );
      console.log("dfsfaegdsfa");
      let newselleritems = this.$store.state.creatorItemList.map(
        (item) => item.id
      );
      let sorted = newselleritems.sort(
        (selleritems, newselleritems) => newselleritems - selleritems
      );
      console.log(sorted);
      if (sorted[0]) {
        this.$store.commit("set", { key: "newitemID", value: sorted[0] });
        await this.$store.dispatch("setSellerItemList");
      }

      this.itemid = await this.$store.state.newitemID;

      console.log(this.itemid);
      this.thisitem = await this.$store.getters.getItemFromListByID(
        this.itemid
      );
      this.e1 = 2;
      this.showpreview = true;

      this.loadingitem = false;
      this.submitted = false;
      this.flight = false;
       }else{
           await this.$store.dispatch(
        "setCreatorItemList",
        this.$store.state.account.address
      );
          this.e1 = 3;
       }
    },
    updateStepCount(e1) {
      this.e1 = e1;
    },
    addRow() {
      console.log("added");
      if (this.data.photos[this.photoId] != "") {
        this.photoId += 1;
        this.data.photos.push("");
      }
    },
    conditionLabel() {
      if (this.data.condition === 0) {
        return "'bad'";
      }
      if (this.data.condition === 1) {
        return "'fixable'";
      }
      if (this.data.condition === 2) {
        return "'decent'";
      }
      if (this.data.condition === 3) {
        return "'as new'";
      }
      if (this.data.condition === 4) {
        return "'perfect'";
      }
    },
    async getTxEncryptionKey(txSenderPrivKey, nonce) {
      const ioExchPubkey = await this.$store.getters.getMasterKey;
      console.log(ioExchPubkey);
      //  let consensusIoPubKey = fromBase64(ioExchPubkey);
      //let consensusIoPubKey = Uint8Array
      // consensusIoPubKey = '21 185 166 251 233 193 0 18 199 108 198 88 151 71 165 142 58 104 137 10 192 190 39 232 147 62 102 58 43 173 184 57'
      //var consensusIoPubKey = new Uint8Array(32);
      /* consensusIoPubKey = [
        21, 185, 166, 251, 233, 193, 0, 18, 199, 108, 198, 88, 151, 71, 165,
        142, 58, 104, 137, 10, 192, 190, 39, 232, 147, 62, 102, 58, 43, 173,
        184, 57,
      ];
*/
      console.log(txSenderPrivKey);
      console.log(Uint8Array.from(txSenderPrivKey));
      // console.log(consensusIoPubKey);

      let masterCert = fromBase64(ioExchPubkey);
      const hkdfSalt = Uint8Array.from([
        0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x02, 0x4b, 0xea,
        0xd8, 0xdf, 0x69, 0x99, 0x08, 0x52, 0xc2, 0x02, 0xdb, 0x0e, 0x00, 0x97,
        0xc1, 0xa1, 0x2e, 0xa6, 0x37, 0xd7, 0xe9, 0x6d,
      ]);
      // let ioExchPubkey = fromBase64(consensusIoPubKey);
      //  console.log( Uint8Array.from(consensusIoPubKey))
      const txEncryptionIkm = x25519(
        Uint8Array.from(txSenderPrivKey),
        masterCert
      );
      const { key: txEncryptionKey } = await hkdf.compute(
        Uint8Array.from([...txEncryptionIkm, ...nonce]),
        "SHA-256",
        32,
        "",
        hkdfSalt
      );
      return txEncryptionKey;
    },

    async encryptMsg(contractCodeHash, msg) {
      let nonce = Random.getBytes(32);
      let random = Random.getBytes(32);

      // console.log(nonce);
      // console.log(random);
      console.log("encrypt start");

      const { privkey, pubkey } = this.GenerateNewKeyPairFromSeed(random);
      this.privkey = privkey;
      this.pubkey = pubkey;

      console.log(this.privkey);

      const txEncryptionKey = await this.getTxEncryptionKey(privkey, nonce);
      console.log("txEncryptionKey:" + txEncryptionKey);

      const siv = await miscreant.SIV.importKey(
        txEncryptionKey,
        "AES-SIV",
        cryptoProvider
      );
      console.log("contractCodeHash:" + contractCodeHash);
      console.log("JSON.stringify(msg):" + JSON.stringify(msg));

      const plaintext = toUtf8(contractCodeHash + JSON.stringify(msg));
      console.log("plaintext:" + plaintext);
      const ciphertext = await siv.seal(plaintext, [new Uint8Array()]);
      console.log("ciphertext:" + ciphertext);
      // ciphertext = nonce(32) || wallet_pubkey(32) || ciphertext
      let intArr = Uint8Array.from([...nonce, ...pubkey, ...ciphertext]);

      return toBase64(intArr);
      // console.log(this.value.init_msg);
    },

    GenerateNewSeed() {
      this.seed = secureRandom.randomUint8Array(32);
    },

    GenerateNewKeyPairFromSeed(seed) {
      const { public: pubkey, private: privkey } = generateKeyPair(seed);
      return { privkey, pubkey };
    },
  },
};
</script>

